<template>
	<div class="page">
		<el-row :gutter="20">
			<el-col :key="index" v-for="(linije, index) in linijeData" :span="12">
				<el-card shadow="hover">
					<el-table
							:data="linije"
							style="width: 100%">
						<el-table-column
								prop="datum"
								:label="$t('statistika.datum')">
							<template slot-scope="scope">
								{{$utils('formatDate', scope.row.datum)}}
							</template>
						</el-table-column>
						<el-table-column
								prop="broj_karata"
								:label="$t('statistika.broj_karata')">
						</el-table-column>
						<el-table-column
								prop="ukupna_cena"
								:label="$t('statistika.ukupna_cena')">
							<template slot-scope="scope">
								{{$utils('formatBroja', scope.row.ukupna_cena, 2)}}
							</template>
						</el-table-column>
						<el-table-column
								prop="ukupno_putnika"
								:label="$t('statistika.ukupno_putnika')">
						</el-table-column>
						<el-table-column>
							<template slot-scope="scope">
								<el-button type="primary" @click="statistikaInfoProdatih(scope.row.id_linije, scope.row.datum)" icon="fas fa-info"/>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>


		<el-dialog
				v-loading="dialogLoading"
				title="Tips"
				:visible.sync="dialogVisible"
				width="30%">
			<el-row>
				<v-chart style="width: auto" :autoresize="true" :options="vBarMapDialog"/>
				<el-col>Prosek sve karte: {{dialogData.prosek_sve_karte}}</el-col>
				<el-col>Prosek sve karte 1 pravac: {{dialogData.prosek_sve_karte_1pravac}}</el-col>
				<el-col>Prosek sve karte povratna: {{dialogData.prosek_sve_karte_povratna}}</el-col>
				<el-col>Prosek srb 1 pravac: {{dialogData.prosek_srb_1pravac}}</el-col>
				<el-col>Prosek srb povratna: {{dialogData.prosek_srb_povratna}}</el-col>
				<el-col>Prosek nesrb 1 pravac: {{dialogData.prosek_nesrb_1pravac}}</el-col>
				<el-col>Prosek nesrb povratna: {{dialogData.prosek_nesrb_povratna}}</el-col>
				<el-col v-for="(grupe, index) in dialogData.grupe_cene" :key="index">
					<span>{{index}}</span>
					<el-col>Kolicina: {{grupe.kolicina}}</el-col>
					<el-col>Cena: {{grupe.cena}}</el-col>
				</el-col>
			</el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">Cancel</el-button>
				<el-button type="primary" @click="dialogVisible = false">Confirm</el-button>
				</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'statistika',
	data() {
		return {
			dialogLoading: false,
			dialogVisible: false,
			dialogData: [],
			linijeData: [],
			labelOption: {
				show: true,
				fontSize: 16,
				rich: {
					name: {
						textBorderColor: '#fff'
					}
				}
			},
			vBarMapDialog: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					data: ['Odr', 'Penz', 'Stud', 'Dec']
				},
				xAxis: {
					type: 'value'
				},
				yAxis: {
					type: 'category',
					data: ['CH1', 'CH2', 'SRB1', 'SRB2']
				},
				series: [
					{
						name: 'Odr',
						type: 'bar',
						stack: '1',
						label: {
							show: true
						},
						data: [111, 222, 333, 444]
					},
					{
						name: 'Penz',
						type: 'bar',
						stack: '1',
						label: {
							show: true
						},
						data: [120, 132, 101, 134]
					},
					{
						name: 'Stud',
						type: 'bar',
						stack: '1',
						label: {
							show: true
						},
						data: [220, 182, 191, 234]
					},
					{
						name: 'Dec',
						type: 'bar',
						stack: '1',
						label: {
							show: true
						},
						data: [111, 222, 333, 444]
					}
				]
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('dashboard.hello')
		});

		this.$utils('stopLoadingAfter', [this.statistikaProdateKarte()]);
	},
	methods: {
		statistikaProdateKarte() {
			return this.$get('statistikaProdateKarte').then(data => {
				this.linijeData = data;
			});
		},
		statistikaInfoProdatih(linija, datum) {
			this.dialogVisible = true;
			this.dialogLoading = true;
			return this.$get('statistikaInfoProdatih', { datum: datum, idLinije: linija }).then(data => {
				this.dialogLoading = false;
				this.dialogData = data;
				this.vBarMapDialog.legend.data = []; // ['Odr', 'Penz', 'Stud', 'Dec']
				this.vBarMapDialog.yAxis.data = []; // ['CH1', 'CH2', 'SRB1', 'SRB2']
				this.vBarMapDialog.series = []; // ['CH1', 'CH2', 'SRB1', 'SRB2']
			});
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
